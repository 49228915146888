.bookingPayment section {
  padding-top: 0;
}

.bookingPayment .quickView {
  padding: 1em;
  border: 1px solid #CCC;
}

@media screen and (max-width: 768px) {
  .bookingPayment .quickView {
    order: -1;
    margin-bottom: 30px;
  }
}
