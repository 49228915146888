#mainNav {
  border-bottom: none;
}

.home .photo-gallery {
  padding-top: 2rem;
}

.home .photo-gallery img {
  width: 100%;
}

.home .deal .fa-circle-info {
  cursor: pointer;
}

.home .contact-form {
  padding: 1em;
  border: 1px solid #CCC;
}
