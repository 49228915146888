.pricing {
    padding-top: 0;
}
@media screen and (max-width: 768px) {
    .pricing .deal-button {
        display: block;
        margin-bottom: 10px;
        width: 100%;
    }
  }
