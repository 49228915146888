.bookingReview section {
  padding-top: 0;
}

.bookingReview .house-rule {
  min-height: 115px;
}

.bookingReview .booking-date-display,
.bookingReview .icon-display {
  text-align: center;
  padding: 0.8em;
}

.bookingReview .icon-display {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.bookingReview .booking-date-display {
  width: 70px;
  height: 70px;
}

.bookingReview .light-bg {
  background-color: #eee;
}

.bookingReview .quickView {
  padding: 1em;
  border: 1px solid #CCC;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .bookingReview .quickView {
    display: none;
  }
}
