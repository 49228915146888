.PaymentDetails {
  display: block;
  border-radius: 15px;
  padding: 0.5rem;
}

.PaymentDetails .table td {
  border-top: 0;
  padding: 0.35rem;
}

.PaymentDetails .overlay-link {
  color: #343a40;
  padding: 0;
  margin: 0;
  text-align: left;
  text-decoration: underline;
}

.PaymentDetails .overlay-link:focus {
  box-shadow: none;
}
